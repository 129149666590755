import React, { useEffect, useState } from "react";
import { OlaMaps } from "../../../libs/OlaMapsWebSDK/olamaps-web-sdk.umd.js";

const Map = () => {
  const [vehicleLocations, setVehicleLocations] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/v0/get-vehicle-locations`
        ); // Replace with your actual API endpoint
        const data = await response.json();
        setVehicleLocations(data); // API returns array of objects with vehicleNumber, lat, lng
      } catch (error) {
        console.error("Failed to fetch locations", error);
      }
    };
    fetchLocations();
  }, []);

  useEffect(() => {
    const olaMaps = new OlaMaps({
      apiKey: process.env.REACT_APP_OLA_MAPS_API_KEY,
    });

    const container = document.getElementById("map-container");
    if (container) {
      const myMap = olaMaps.init({
        container: "map-container",
        style:
          "https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json",
        center: [78.9629, 20.5937], // Center of India (Longitude, Latitude)
        zoom: 5, // Adjust zoom level to show all of India
      });

      vehicleLocations.forEach((location) => {
        const blueDot = document.createElement("div");
        blueDot.style.width = "12px";
        blueDot.style.height = "12px";
        blueDot.style.borderRadius = "50%";
        blueDot.style.backgroundColor = "blue";
        blueDot.style.border = "2px solid white";

        const marker = olaMaps
          .addMarker({ element: blueDot, offset: [0, -10], anchor: "center" })
          .setLngLat([location.lng, location.lat])
          .addTo(myMap);

        const popup = olaMaps
          .addPopup({ offset: [0, -20], anchor: "center" })
          .setHTML(
            `<b>Vehicle:</b> ${location.vehicleNumber}<br>
             <b>Lat:</b> ${location.lat}<br>
             <b>Lng:</b> ${location.lng}<br>
             <b>Timestamp:</b> ${new Date(location.timestamp).toLocaleString()}`
          );

        // Attach popup to the marker
        marker.setPopup(popup);

        // Add event listener for click
        marker.on("click", () => {
          popup.addTo(myMap);
        });
      });
    } else {
      console.error('Map container with id "map-container" not found.');
    }
  }, [vehicleLocations]);

  return (
    <div id="map-container" style={{ height: "100vh", width: "100%" }}></div>
  );
};

export default Map;
