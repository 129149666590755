import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import "./TyreMaintenance.css"; // Import the CSS file

// Register necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

const TyreMaintenance = () => {
  const [vehicleData, setVehicleData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [chartData /*setChartData*/] = useState({
    labels: ["0-50 km", "50-100 km", "100-200 km", "200+ km"],
    datasets: [
      {
        label: "Vehicles",
        data: [10, 20, 15, 5],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50"],
        hoverOffset: 4,
      },
    ],
  });

  useEffect(() => {
    // Simulate fetching data with a timeout
    const fetchData = async () => {
      const sampleData = [
        {
          vehicleNumber: "AB1234",
          driverName: "John Doe",
          driverContact: "1234567890",
          odometer: "45000",
          distance: 40,
          fuelConsumed: "10L",
          location: "New York",
          vehicleStatus: "Active",
          fuelStatus: "Full",
        },
        {
          vehicleNumber: "CD5678",
          driverName: "Jane Smith",
          driverContact: "9876543210",
          odometer: "65200",
          distance: 80,
          fuelConsumed: "15L",
          location: "Los Angeles",
          vehicleStatus: "Inactive",
          fuelStatus: "Half",
        },
        {
          vehicleNumber: "EF9012",
          driverName: "Tom Brown",
          driverContact: "1122334455",
          odometer: "73200",
          distance: 120,
          fuelConsumed: "20L",
          location: "Chicago",
          vehicleStatus: "Active",
          fuelStatus: "Low",
        },
        {
          vehicleNumber: "GH3456",
          driverName: "Lisa White",
          driverContact: "6677889900",
          odometer: "89000",
          distance: 250,
          fuelConsumed: "25L",
          location: "Houston",
          vehicleStatus: "Maintenance",
          fuelStatus: "Empty",
        },
      ];
      setVehicleData(sampleData);
      setFilteredData(sampleData);
    };

    fetchData();
  }, []);

  const handlePieClick = (event, elements) => {
    if (!elements.length) return;

    const clickedIndex = elements[0].index;
    const ranges = [
      { min: 0, max: 50 },
      { min: 50, max: 100 },
      { min: 100, max: 200 },
      { min: 200, max: Infinity },
    ];
    const range = ranges[clickedIndex];

    const filtered = vehicleData.filter(
      (vehicle) => vehicle.distance >= range.min && vehicle.distance < range.max
    );
    setFilteredData(filtered);
  };

  return (
    <div className="tyre-maintenance-container">
      <h2 className="title">Tyre Maintenance - KM Record</h2>
      <div className="chart-container">
        <Pie
          data={chartData}
          options={{
            maintainAspectRatio: false, // Allow chart resizing
            plugins: {
              tooltip: {
                callbacks: {
                  label: (tooltipItem) =>
                    `${tooltipItem.label}: ${tooltipItem.raw} vehicles`,
                },
              },
            },
          }}
          height={200} // Set desired height
          width={200} // Set desired width
          onClick={(event, elements) => handlePieClick(event, elements)}
        />
      </div>
      <h3 className="subtitle">List View</h3>
      <div className="table-container">
        <table className="data-table">
          <thead>
            <tr>
              <th>Vehicle No.</th>
              <th>Driver Name</th>
              <th>Driver Contact</th>
              <th>Odometer</th>
              <th>Distance (km)</th>
              <th>Fuel Consumed</th>
              <th>Location</th>
              <th>Vehicle Status</th>
              <th>Fuel Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((vehicle, index) => (
              <tr key={index}>
                <td>{vehicle.vehicleNumber}</td>
                <td>{vehicle.driverName}</td>
                <td>{vehicle.driverContact}</td>
                <td>{vehicle.odometer}</td>
                <td>{vehicle.distance}</td>
                <td>{vehicle.fuelConsumed}</td>
                <td>{vehicle.location}</td>
                <td>{vehicle.vehicleStatus}</td>
                <td>{vehicle.fuelStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TyreMaintenance;
