import React, { Fragment, useState } from "react";
import "../styles/Profile.css";
import ProfilePageHeader from "../Components/UserDashboard/MainSectionComponents/ProfilePageHeader";

const Profile = () => {
  const [alternateEmails, setAlternateEmails] = useState([]);
  const [alternatePhones, setAlternatePhones] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [newPhone, setNewPhone] = useState("");

  // Helper Functions
  const handleAddEmail = () => {
    if (newEmail && validateEmail(newEmail)) {
      setAlternateEmails((prev) => [...prev, newEmail]);
      setNewEmail("");
    } else {
      alert("Please enter a valid email address.");
    }
  };

  const handleAddPhone = () => {
    if (newPhone && validatePhone(newPhone)) {
      setAlternatePhones((prev) => [...prev, newPhone]);
      setNewPhone("");
    } else {
      alert("Please enter a valid phone number with a country code.");
    }
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validatePhone = (phone) => /^\+\d{1,3}\s?\d{4,14}$/.test(phone); // International format

  const deleteAlternateEmail = (index) => {
    setAlternateEmails((prev) => prev.filter((_, i) => i !== index));
  };

  const deleteAlternatePhone = (index) => {
    setAlternatePhones((prev) => prev.filter((_, i) => i !== index));
  };

  // JSX Structure
  return (
    <Fragment>
      <ProfilePageHeader />
      <div className="account-settings">
        <h1 className="settings-title">Account Settings</h1>

        <section className="settings-section">
          <div className="settings-item">
            <label>Username:</label>
            <span>User</span>
          </div>
          <div className="settings-item">
            <label>Phone Number:</label>
            <span>+91xxxxxxxxx</span>
          </div>
          <div className="settings-item">
            <label>Email:</label>
            <span>user@domain.com</span>
          </div>
        </section>

        {/* Alternate Emails */}
        <section className="settings-section">
          <h2>Alternate Emails</h2>
          {alternateEmails.length > 0 ? (
            <ul className="list-group">
              {alternateEmails.map((email, index) => (
                <li key={index} className="list-item">
                  {email}
                  <button
                    className="delete-button"
                    onClick={() => deleteAlternateEmail(index)}
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No alternate emails added yet.</p>
          )}
          <div className="input-group">
            <input
              type="email"
              placeholder="Enter alternate email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              className="input-field"
            />
            <button className="add-button" onClick={handleAddEmail}>
              Add Email
            </button>
          </div>
        </section>

        {/* Alternate Phone Numbers */}
        <section className="settings-section">
          <h2>Alternate Phone Numbers</h2>
          {alternatePhones.length > 0 ? (
            <ul className="list-group">
              {alternatePhones.map((phone, index) => (
                <li key={index} className="list-item">
                  {phone}
                  <button
                    className="delete-button"
                    onClick={() => deleteAlternatePhone(index)}
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No alternate phone numbers added yet.</p>
          )}
          <div className="input-group">
            <input
              type="text"
              placeholder="Enter alternate phone (e.g., +1 1234567890)"
              value={newPhone}
              onChange={(e) => setNewPhone(e.target.value)}
              className="input-field"
            />
            <button className="add-button" onClick={handleAddPhone}>
              Add Phone
            </button>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default Profile;
