import React from "react";
import { useNavigate } from "react-router-dom";
import logoVeyzaDark from "../../../assets/logoVeyzaDark.png";
const ProfilePageHeader = () => {
  //prebuilt function used to redirect to required url
  const navigate = useNavigate();
  const handleHomeRedirect = () => {
    navigate("/dashboard"); // navigate to dashboard
  };

  return (
    <nav className="navbar">
      {/*Logo  */}
      <div className="navbar-logo">
        <img src={logoVeyzaDark} alt="Venzaya Logo" />
      </div>

      {/*Redirect to Homepage */}
      <ul className="login-navbar-links">
        <li>
          <button className="nav-button" onClick={handleHomeRedirect}>
            Home
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default ProfilePageHeader;
