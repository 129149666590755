import React, { useState } from "react";
import "./Alerts.css";

const Alerts = () => {
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTimeRange, setSelectedTimeRange] = useState("last24");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customStartTime, setCustomStartTime] = useState("00:00");
  const [customEndDate, setCustomEndDate] = useState("");
  const [customEndTime, setCustomEndTime] = useState("23:59");
  const [searchText, setSearchText] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  // Mock alert data
  const alerts = [
    { id: 1, type: 'Fuel Alert', description: "Fuel level is critically low.", timestamp: " 10:30AM 2024-12-19", vehicleNo: "AB123" },
    { id: 2, type: "Driver Behavior", description: "Harsh braking detected.", timestamp: "2024-12-19 11:15 AM", vehicleNo: "CD456" },
    { id: 3, type: "Vehicle Health", description: "Engine temperature is too high.", timestamp: "2024-12-19 12:00 PM", vehicleNo: "EF789" },
    { id: 4, type: "Fuel Sensor Disconnect Alert", description: "Fuel sensor has disconnected.", timestamp: "2024-12-19 1:30 PM", vehicleNo: "GH101" },
    { id: 5, type: "Harsh Braking", description: "Harsh braking detected.", timestamp: "2024-12-19 2:00 PM", vehicleNo: "IJ112" },
    { id: 6, type: "Overspeed", description: "Vehicle is overspeeding.", timestamp: "2024-12-19 3:00 PM", vehicleNo: "KL131" },
  ];

  // List of available filter options
  const filterOptions = [
    "Conditional Maintenance",
    "Continous Driving",
    "Device Battery Low",
    "Device Disconnected",
    "DTC Alert",
    "Free Running",
    "Fuel Sensor Disconnect Alert",
    "Fuel Sensor Stuck Alert",
    "Geofence Breach",
    "Halt",
    "Harsh Acceleration",
    "Harsh Braking",
    "Idle",
    "Ignition Wire Disconnect Alert",
    "No Response",
    "Overspeed",
    "Panic",
    "Pilferage",
    "Refuel",
    "Route fence Breach IN/OUT",
    "Scheduled Maintenance",
    "Towing",
    "Trip Alert",
    "Vehicle Battery Low"
  ];

  const handleFilterToggle = () => {
    setIsModalOpen(true);
  };

  const handleFilterChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedFilters((prevFilters) => [...prevFilters, value]);
    } else {
      setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter !== value));
    }
  };

  const applyFilters = () => {
    setIsModalOpen(false);
  };

  const handleTimeRangeChange = (e) => {
    const value = e.target.value;
    setSelectedTimeRange(value);
    if (value !== "custom") {
      setCustomStartDate("");
      setCustomEndDate("");
      setCustomStartTime("00:00");
      setCustomEndTime("23:59");
    }
  };

  const handleCustomDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") setCustomStartDate(value);
    if (name === "endDate") setCustomEndDate(value);
  };

  const handleCustomTimeChange = (e) => {
    const { name, value } = e.target;
    if (name === "startTime") setCustomStartTime(value);
    if (name === "endTime") setCustomEndTime(value);
  };

  const handleReset = () => {
    setCustomStartDate("");
    setCustomEndDate("");
    setCustomStartTime("00:00");
    setCustomEndTime("23:59");
  };

  const handleSubmit = () => {
    console.log("Custom Range Submitted:", {
      startDate: customStartDate,
      startTime: customStartTime,
      endDate: customEndDate,
      endTime: customEndTime,
    });
  };

  const filteredAlerts = alerts.filter((alert) =>
    (selectedFilters.length === 0 || selectedFilters.includes(alert.type)) &&
    (alert.vehicleNo.toLowerCase().includes(searchText.toLowerCase()) || searchText === "")
  );

  const ContactForm = ({ onClose }) => {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Add Ticket</h2>
          <form>
            <div className="form-group">
              <label>Vehicle Number</label>
              <input type="text" placeholder="Enter Vehicle Number" />
            </div>
            <div className="form-group">
              <label>Contact Name</label>
              <input type="text" placeholder="Enter Contact Name" />
            </div>
            <div className="form-group">
              <label>Email <span className="required">*</span></label>
              <input type="email" placeholder="Enter Email Address" required />
            </div>
            <div className="form-group">
              <label>Complaint Type <span className="required">*</span></label>
              <select required>
                <option value="device-offline">Device offline</option>
                <option value="fuel-issue">Fuel Issue</option>
                <option value="engine-problem">Engine Problem</option>
                {/* Add other options as needed */}
              </select>
            </div>
            <div className="form-group">
              <label>Subject <span className="required">*</span></label>
              <input type="text" placeholder="Enter Subject" required />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea rows="5" placeholder="Enter Description"></textarea>
            </div>
            <div>
            </div>
            <div className="form-actions">
              <button type="button" className="reset-btn" onClick={() => alert("Form Reset!")}>
                Reset
              </button>
              <button type="submit" className="submit-btn">
                Submit
              </button>
            </div>
          </form>
          <button className="close-btn" onClick={onClose}>×</button>
        </div>
      </div>
    );
  };

  return (
    <div className="alert-list">
      <h2>Alerts</h2>
      <p>Click on an alert to view more details.</p>

      <div className="search-filter-container">
        <input
          type="text"
          className="search-box"
          placeholder="Search Vehicle No."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button className="filter-btn" onClick={handleFilterToggle}>
          Filter Settings
        </button>
      </div>

      <div className="time-range-container">
        <label htmlFor="time-range">Select Time Range:</label>
        <select
          id="time-range"
          value={selectedTimeRange}
          onChange={handleTimeRangeChange}
        >
          <option value="last24">Last 24 hours</option>
          <option value="last7">Last 7 days</option>
          <option value="last15">Last 15 days</option>
          <option value="last30">Last 30 days</option>
          <option value="custom">Custom</option>
        </select>
      </div>

      {selectedTimeRange === "custom" && (
        <div className="custom-date-selection">
          <div className="date-time-container">
            <div className="start-date">
              <label htmlFor="startDate">Start Date:</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={customStartDate}
                onChange={handleCustomDateChange}
              />
              <input
                type="time"
                id="startTime"
                name="startTime"
                value={customStartTime}
                onChange={handleCustomTimeChange}
              />
            </div>

            <div className="end-date">
              <label htmlFor="endDate">End Date:</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={customEndDate}
                onChange={handleCustomDateChange}
              />
              <input
                type="time"
                id="endTime"
                name="endTime"
                value={customEndTime}
                onChange={handleCustomTimeChange}
              />
            </div>
          </div>
          <div className="actions">
            <button className="reset-btn" onClick={handleReset}>
              Reset
            </button>
            <button className="submit-btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="filter-modal">
          <div className="filter-modal-content">
            <h4>Select Alert Types</h4>
            <div className="filter-options">
              {filterOptions.map((option) => (
                <div key={option} className="filter-option">
                  <input
                    type="checkbox"
                    id={option}
                    value={option}
                    checked={selectedFilters.includes(option)}
                    onChange={handleFilterChange}
                  />
                  <label htmlFor={option}>{option}</label>
                </div>
              ))}
            </div>
            <div className="modal-actions">
              <button className="apply-filter-btn" onClick={applyFilters}>
                Apply Filter
              </button>
              <button className="cancel-btn" onClick={() => setIsModalOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <table className="alert-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Timestamp</th>
            <th>Vehicle No</th>
          </tr>
        </thead>
        <tbody>
          {filteredAlerts.map((alert) => (
            <tr
              key={alert.id}
              className={selectedAlert?.id === alert.id ? "selected" : ""}
              onClick={() => setSelectedAlert(alert)}
            >
              <td>{alert.type}</td>
              <td>{alert.timestamp}</td>
              <td>{alert.vehicleNo}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedAlert && (
        <div className="alert-details">
          <h3>Alert Details</h3>
          <p><strong>Type:</strong> {selectedAlert.type}</p>
          <p><strong>Description:</strong> {selectedAlert.description}</p>
          <p><strong>Timestamp:</strong> {selectedAlert.timestamp}</p>
          <p><strong>Vehicle No:</strong> {selectedAlert.vehicleNo}</p>
          <button className="close-btn" onClick={() => setSelectedAlert(null)}>
            Close
          </button>
        </div>
      )}

      {isContactFormOpen && <ContactForm onClose={() => setIsContactFormOpen(false)} />}
    </div>
  );
};

export default Alerts;
