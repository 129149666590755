import React from "react";

const MainSection = ({ content }) => {
  return (
    <div className="main-content">
      <div className="content">
        <p>{content}</p>
      </div>
    </div>
  );
};

export default MainSection;
