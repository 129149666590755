import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import './VehicleService.css'; // Import the CSS file

// Register necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

const VehicleService = () => {
  const [vehicleServiceData, setVehicleServiceData] = useState([]);
  const [filteredServiceData, setFilteredServiceData] = useState([]);
  const [workshopData, setWorkshopData] = useState([]);
  const [chartData, setChartData] = useState({
    labels: ['Workshop A', 'Workshop B', 'Workshop C', 'Workshop D'],
    datasets: [
      {
        label: 'Vehicles at Workshop',
        data: [10, 20, 15, 5], // Default sample values
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4CAF50'],
        hoverOffset: 4,
      },
    ],
  });

  // Simulate fetching data with useEffect
  useEffect(() => {
    // Sample vehicle service data
    const sampleVehicleData = [
      {
        vehicleNumber: 'MH12AB1234',
        driverName: 'John Doe',
        driverContact: '9876543210',
        lastServiceKm: 5000,
        kmSinceLastService: 1500,
        serviceType: 'Oil Change',
        serviceCenter: 'Workshop A',
        serviceStatus: 'Completed',
      },
      {
        vehicleNumber: 'MH12CD5678',
        driverName: 'Jane Smith',
        driverContact: '8765432109',
        lastServiceKm: 10000,
        kmSinceLastService: 300,
        serviceType: 'Brake Inspection',
        serviceCenter: 'Workshop B',
        serviceStatus: 'Pending',
      },
      {
        vehicleNumber: 'MH12EF9012',
        driverName: 'Bob Lee',
        driverContact: '9988776655',
        lastServiceKm: 15000,
        kmSinceLastService: 1000,
        serviceType: 'Tyre Rotation',
        serviceCenter: 'Workshop C',
        serviceStatus: 'In Progress',
      },
    ];

    // Sample workshop data
    const sampleWorkshopData = [
      { name: 'Workshop A', vehicleCount: 10 },
      { name: 'Workshop B', vehicleCount: 20 },
      { name: 'Workshop C', vehicleCount: 15 },
      { name: 'Workshop D', vehicleCount: 5 },
    ];

    // Update state with sample data
    setVehicleServiceData(sampleVehicleData);
    setFilteredServiceData(sampleVehicleData);
    setWorkshopData(sampleWorkshopData);

    // Prepare chart data
    const workshopCounts = sampleWorkshopData.map(
      (workshop) => workshop.vehicleCount
    );
    setChartData((prevState) => ({
      ...prevState,
      datasets: [{ ...prevState.datasets[0], data: workshopCounts }],
    }));
  }, []);

  const handlePieClick = (event, elements) => {
    if (!elements.length) return;

    const clickedIndex = elements[0].index;
    const selectedWorkshop = workshopData[clickedIndex];

    const filtered = vehicleServiceData.filter(
      (vehicle) => vehicle.serviceCenter === selectedWorkshop.name
    );
    setFilteredServiceData(filtered);
  };

  return (
    <div className="vehicle-service-container">
      <h2 className="title">Vehicle Service</h2>
      <div className="chart-container">
        <Pie
          data={chartData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (tooltipItem) =>
                    `${tooltipItem.label}: ${tooltipItem.raw} vehicles`,
                },
              },
            },
          }}
          height={200}
          width={200}
          onClick={(event, elements) => handlePieClick(event, elements)}
        />
      </div>
      <h3 className="subtitle">List View</h3>
      <div className="table-container">
        <table className="data-table">
          <thead>
            <tr>
              <th>Vehicle No.</th>
              <th>Driver Name</th>
              <th>Driver Contact</th>
              <th>Last Service KM</th>
              <th>KMs Since Service</th>
              <th>Service Type</th>
              <th>Service Center</th>
              <th>Service Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredServiceData.map((vehicle, index) => (
              <tr key={index}>
                <td>{vehicle.vehicleNumber}</td>
                <td>{vehicle.driverName}</td>
                <td>{vehicle.driverContact}</td>
                <td>{vehicle.lastServiceKm}</td>
                <td>{vehicle.kmSinceLastService}</td>
                <td>{vehicle.serviceType}</td>
                <td>{vehicle.serviceCenter}</td>
                <td>{vehicle.serviceStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VehicleService;
