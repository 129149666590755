import React from "react";

const Dropdown = ({ items, onItemClick }) => {
  return (
    <ul className="sidebar-dropdown-menu">
      {items.map((item, index) => (
        <li
          key={index}
          className="sidebar-dropdown-item"
          onClick={() => onItemClick(item)}
        >
          {item}
        </li>
      ))}
    </ul>
  );
};

export default Dropdown;
