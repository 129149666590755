import React from 'react';
import './RouteOptimization.css';

function RouteOptimization() {
  // Sample data
  const sampleData = {
    totalTrips: 150,
    totalStops: 420,
    missedWaypoints: 12,
    scheduledVsActualTime: '4 hrs',
    scheduledVsActualDistance: '15 km',
  };

  return (
    <div className="routes-dashboard">
      <h1>Routes Dashboard</h1>
      <div className="button-container">
        <button>Bulk Upload</button>
        <button>View Routes & Analytics</button>
        <button>Create Route</button>
      </div>
      <div className="stats-container">
        <div className="stat-card">
          <h3>Search Route</h3>
          <input type="text" placeholder="Search..." />
        </div>
        <div className="stat-card">
          <h3>Total Trips</h3>
          <p>{sampleData.totalTrips}</p>
        </div>
        <div className="stat-card">
          <h3>Total Stops</h3>
          <p>{sampleData.totalStops}</p>
        </div>
        <div className="stat-card">
          <h3>Missed Waypoints</h3>
          <p>{sampleData.missedWaypoints}</p>
        </div>
        <div className="stat-card">
          <h3>Scheduled vs Actual Time</h3>
          <p>{sampleData.scheduledVsActualTime}</p>
        </div>
        <div className="stat-card">
          <h3>Scheduled vs Actual Distance</h3>
          <p>{sampleData.scheduledVsActualDistance}</p>
        </div>
      </div>
    </div>
  );
}

export default RouteOptimization;
