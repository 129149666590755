import React, { useState, useEffect } from "react";
import "./ManageSubscription.css";

const ManageSubscription = () => {
  // const [
  /*subscriptions setSubscriptions*/
  // ] = useState([]);
  const [filteredSubscriptions /* setFilteredSubscriptions*/] = useState([]);
  const [filterOption, setFilterOption] = useState("");

  // Fetch data from the backend on component mount
  useEffect(() => {
    // fetchSubscriptions();
  }, []);

  useEffect(
    () => {
      // applyFilter();
    },
    [
      /*filterOption*/
    ]
  );

  // Fetch subscriptions from the backend
  // const fetchSubscriptions = async () => {
  //   try {
  //     const response = await fetch(""); // Backend API endpoint
  //     const data = await response.json();

  //     // Assume backend sends data sorted by expiration date
  //     const sortedData = data.sort(
  //       (a, b) => new Date(a.expireDate) - new Date(b.expireDate)
  //     );

  //     setSubscriptions(sortedData);
  //     setFilteredSubscriptions(sortedData);
  //   } catch (error) {
  //     console.error("Error fetching subscriptions:", error);
  //   }
  // };

  // Apply filtering based on dropdown selection
  // const applyFilter = () => {
  //   if (filterOption) {
  //     const today = new Date();
  //     let futureDate = new Date();

  //     if (filterOption === "7") {
  //       futureDate.setDate(today.getDate() + 7);
  //     } else if (filterOption === "15") {
  //       futureDate.setDate(today.getDate() + 15);
  //     } else if (filterOption === "30") {
  //       futureDate.setDate(today.getDate() + 30);
  //     }

  //     const filtered = subscriptions.filter(
  //       (sub) =>
  //         new Date(sub.expireDate) >= today &&
  //         new Date(sub.expireDate) <= futureDate
  //     );
  //     setFilteredSubscriptions(filtered);
  //   } else {
  //     setFilteredSubscriptions(subscriptions);
  //   }
  // };

  return (
    <div className="subscription-container">
      <h1>Manage Subscription</h1>

      {/* Filter Section */}
      <div className="filter-section">
        <label htmlFor="filterDropdown">Filter by Expire Date:</label>
        <select
          id="filterDropdown"
          value={filterOption}
          onChange={(e) => setFilterOption(e.target.value)}
          className="filter-dropdown"
        >
          <option value="">All</option>
          <option value="7">Next 7 Days</option>
          <option value="15">Next 15 Days</option>
          <option value="30">Next 30 Days</option>
        </select>
      </div>

      {/* Subscriptions Table */}
      <table className="subscription-table">
        <thead>
          <tr>
            <th>Expire Date</th>
            <th>Vehicle Number</th>
            <th>Unique ID</th>
            <th>Login ID</th>
          </tr>
        </thead>
        <tbody>
          {filteredSubscriptions.length > 0 ? (
            filteredSubscriptions.map((sub) => (
              <tr key={sub.id}>
                <td>{new Date(sub.expireDate).toLocaleDateString()}</td>
                <td>{sub.vehicleNumber}</td>
                <td>{sub.uniqueId}</td>
                <td>{sub.loginId}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="no-data">
                No subscriptions found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ManageSubscription;
