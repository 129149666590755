import React, { useState } from "react";
import "./User.css";

const User = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    location: "",
    email: "user@example.com", // Uneditable
    contactNumber: "",
    vehicleGroup: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    console.log("User Details Saved:", formData);
    setIsDropdownOpen(false);
    // Integrate backend logic here to save user details
  };

  const handleCancel = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div className="users-roles-container">
      <div className="tab-menu">
        <button className="tab active">USERS</button>
        {/* 
          The "ROLES" button is currently hidden using inline styles.
          To make it visible, remove the 'display: none' style or update its logic for visibility.
        */}
        <button className="tab" style={{ display: "none" }}>
          ROLES
        </button>
      </div>
      <div className="content-section">
        <div className="header">
          <h2>Users</h2>
          <button
            className="add-user-button"
            onClick={() => setIsDropdownOpen((prev) => !prev)}
          >
            ADD USER
          </button>
        </div>
        {isDropdownOpen && (
          <div className="add-user-dropdown">
            <h3 className="dropdown-title">Add User</h3>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                placeholder="Enter username"
                className="input-field"
              />
            </div>
            <div className="form-group">
              <label htmlFor="location">Location</label>
              <input
                type="text"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                placeholder="Enter location"
                className="input-field"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                className="input-field"
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="contactNumber">Contact Number</label>
              <input
                type="text"
                id="contactNumber"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleInputChange}
                placeholder="Enter contact number"
                className="input-field"
              />
            </div>
            <div className="form-group">
              <label htmlFor="vehicleGroup">Vehicle Group</label>
              <input
                type="text"
                id="vehicleGroup"
                name="vehicleGroup"
                value={formData.vehicleGroup}
                onChange={handleInputChange}
                placeholder="Enter vehicle group"
                className="input-field"
              />
            </div>
            <div className="form-actions">
              <button className="save-btn" onClick={handleSave}>
                Save
              </button>
              <button className="cancel-btn" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </div>
        )}
        <div className="table">
          <div className="table-header">
            <span>User Name</span>
            <span>Assigned Role</span>
            <span>Edit</span>
            <span>Delete</span>
            <span>Reset Password</span>
            <span>Status</span>
          </div>
          <div className="table-body">
            <p>No rows</p>
          </div>
        </div>
        <div className="pagination">
          <p>Rows per page: 5</p>
          <div className="pagination-controls">
            <button>&lt;&lt;</button>
            <button>&lt;</button>
            <button>&gt;</button>
            <button>&gt;&gt;</button>
          </div>
          <p>0–0 of 0</p>
        </div>
      </div>
    </div>
  );
};

export default User;
