import React, { useState } from "react";
import "./UnscheduledStoppage.css";
import unscheduledStoppage from "../../../data/test/unscheduledStoppage.json";

const UnscheduledStoppage = () => {
  const [timeDurations, setTimeDurations] = useState([
    { label: "<20 Min", count: 32 },
    { label: "20-60 Min", count: 2 },
    { label: ">1 Hrs", count: 1 },
  ]);
  const [isEditing, setIsEditing] = useState(false);

  const handleDurationChange = (index, value) => {
    const updatedDurations = [...timeDurations];
    updatedDurations[index].label = value;
    setTimeDurations(updatedDurations);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="dashboard-container">
      {/* Unscheduled Stoppage Section */}
      <div className="unscheduled-stoppage">
        <div className="table-header">
          <h2>Unscheduled Stoppage</h2>
          <button onClick={handleEditToggle}>
            {isEditing ? "Save Changes" : "Customize"}
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th>Time Duration</th>
              <th>Vehicles</th>
            </tr>
          </thead>
          <tbody>
            {timeDurations.map((duration, index) => (
              <tr key={index}>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={duration.label}
                      onChange={(e) =>
                        handleDurationChange(index, e.target.value)
                      }
                    />
                  ) : (
                    duration.label
                  )}
                </td>
                <td>{duration.count}</td>
              </tr>
            ))}
            <tr className="grand-total">
              <td>Grand total</td>
              <td>{timeDurations.reduce((sum, d) => sum + d.count, 0)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* List View Table */}
      <div className="list-view">
        <table>
          <thead>
            <tr>
              <th>Vehicle No</th>
              <th>Driver Name</th>
              <th>Unscheduled stoppage</th>
              <th>Time Duration</th>
              <th>Geofence stoppage</th>
              <th>Time Duration</th>
              <th>Idling</th>
              <th>Time Duration</th>
              <th>Daily running KM</th>
              <th>KM</th>
            </tr>
          </thead>
          <tbody>
            {/* Mock data */}
            {unscheduledStoppage.map((data, index) => (
              <tr key={index}>
                <td>{data.vehicleNumber}</td>
                <td>{data.driverName}</td>
                <td>{data.unscheduledStoppage}</td>
                <td>{data.timeDuration}</td>
                <td>{data.geofenceStoppage}</td>
                <td>{data.geofenceTimeDuration}</td>
                <td>{data.idling}</td>
                <td>{data.idlingTimeDuration}</td>
                <td>{data.dailyRunningKM}</td>
                <td>{data.kilometers}</td>
              </tr>
            ))}
            {/* Add more rows as necessary */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UnscheduledStoppage;
