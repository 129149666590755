import React from 'react';
import './DriverTraining.css';

function DriverTraining() {
  return (
    <div className="driver-training">
      <div className="coming-soon-container">
        <h1 className="coming-soon-title">Coming Soon</h1>
        <p className="coming-soon-subtext">
          We're working  to bring you something amazing. Stay tuned!
        </p>
      </div>
    </div>
  );
}

export default DriverTraining;

