import React, { useState } from "react";
import DropDown from "./DropDown";

const LeftSidebar = ({ setActiveComponent }) => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const menuItems = [
    {
      name: "Map View",
      dropdown: [],
    },
    {
      name: "Fleet Operations",
      dropdown: [
        "Alerts",
        "Geofences",
        "Trips",
        "Route Optimization",
        "Reports",
      ],
    },
    {
      name: "Fleet Utilization",
      dropdown: [
        "Unscheduled Stoppage",
        "Geofence Stoppage",
        "Idling",
        "Daily Running Km",
      ],
    },
    {
      name: "Fleet Maintainence",
      dropdown: ["Tyre Maintenance", "Vehicle Service", "Workshop"],
    },
    {
      name: "Administration",
      dropdown: ["Vehicle", "User", "Driver"],
    },
    {
      name: "Subscription",
      dropdown: ["Manage Subscription", "Payments"],
    },
     //{
     // name: "Settings",
      //dropdown: ["Account Settings"],
    //},
    {
      name: "Driver",
      dropdown: ["Driver Training"],
    },
    {
      name: "Support",
      dropdown: ["Veyza Support"],
    },
    
  ];

  const toggleDropdown = (index) => {
    setOpenDropdown((prev) => (prev === index ? null : index));
  };

  const handleDropdownItemClick = (item) => {
    setActiveComponent(item); // Update active component on dropdown item click
  };

  return (
    <div className="sidebar">
      <ul className="sidebar-menu">
        {menuItems.map((item, index) => (
          <li key={index} className="sidebar-item">
            <div
              className="menu-item"
              onClick={() => {
                toggleDropdown(index);
                if (!item.dropdown.length) setActiveComponent(item.name);
              }}
            >
              {item.name}
            </div>
            {openDropdown === index && item.dropdown.length > 0 && (
              <DropDown
                items={item.dropdown}
                onItemClick={handleDropdownItemClick}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeftSidebar;
