import React, { useState, useEffect } from 'react';
import './Vehicle.css';

const Vehicle = () => {
  const [vehicles, setVehicles] = useState([]);
  const [groups, setGroups] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    groupName: '',
    vehicleNumber: '',
  });
  const [editIndex, setEditIndex] = useState(null);

  useEffect(() => {
    loadVehicles();
    loadGroups();
  }, []);

  const loadVehicles = async () => {
    console.log('Fetching vehicles...');
    setVehicles([
      { vehicleNumber: 'V123', serialNumber: 'S456', deviceId: 'D789', simNumber: '+11234567890' },
    ]);
  };

  const loadGroups = async () => {
    console.log('Fetching groups...');
    setGroups([
      { name: 'Group A', assignedVehicles: 'V123', createdDate: '2024-12-01' },
    ]);
  };

  const handleAddGroup = () => {
    setShowForm(true);
    setFormData({ groupName: '', vehicleNumber: '' });
    setEditIndex(null);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!formData.groupName || !formData.vehicleNumber) {
      alert('Please fill out all fields.');
      return;
    }

    const newGroup = {
      name: formData.groupName,
      assignedVehicles: formData.vehicleNumber,
      createdDate: new Date().toISOString().split('T')[0],
    };

    if (editIndex !== null) {
      const updatedGroups = [...groups];
      updatedGroups[editIndex] = newGroup;
      setGroups(updatedGroups);
    } else {
      setGroups([...groups, newGroup]);
    }

    setShowForm(false);
    setFormData({ groupName: '', vehicleNumber: '' });
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setFormData({
      groupName: groups[index].name,
      vehicleNumber: groups[index].assignedVehicles,
    });
    setShowForm(true);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    setGroups(groups.filter((_, i) => i !== index));
  };

  const handleBulkUpload = () => {
    console.log('Bulk upload initiated');
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.csv';
    fileInput.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        console.log('File selected for bulk upload:', file.name);
      }
    };
    fileInput.click();
  };

  const handlePagination = (direction, section) => {
    console.log(`Pagination for ${section} in direction: ${direction}`);
  };

  const handleExport = (section) => {
    console.log(`${section} export initiated`);
  };

  return (
    <div className="container">
      {/* Vehicles Section */}
      <div className="section vehicles">
        <div className="header">
          <h2>Vehicles</h2>
          <div className="header-actions">
            <button onClick={() => handleExport('Vehicles')}>EXPORT</button>
          </div>
        </div>
        <div className="table">
          <div className="table-header">
            <span>Vehicle Number</span>
            <span>Serial Number</span>
            <span>Unique Device Id</span>
            <span>SIM Number</span>
          </div>
          <div className="table-body">
            {vehicles.length === 0 ? (
              <p>No rows</p>
            ) : (
              vehicles.map((vehicle, index) => (
                <div key={index} className="table-row">
                  <span>{vehicle.vehicleNumber}</span>
                  <span>{vehicle.serialNumber}</span>
                  <span>{vehicle.deviceId}</span>
                  <span>{vehicle.simNumber}</span>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="pagination">
          <p>Rows per page: 5</p>
          <div className="pagination-controls">
            <button onClick={() => handlePagination('first', 'Vehicles')}>&lt;&lt;</button>
            <button onClick={() => handlePagination('prev', 'Vehicles')}>&lt;</button>
            <button onClick={() => handlePagination('next', 'Vehicles')}>&gt;</button>
            <button onClick={() => handlePagination('last', 'Vehicles')}>&gt;&gt;</button>
          </div>
          <p>0–0 of {vehicles.length}</p>
        </div>
      </div>

      {/* Groups Section */}
      <div className="section groups">
        <div className="header">
          <h2>Groups</h2>
          <div className="header-actions">
            <button onClick={() => handleExport('Groups')}>EXPORT</button>
          </div>
          <div className="group-actions">
            <button className="add-group" onClick={handleAddGroup}>
              ADD GROUP
            </button>
            <button className="bulk-upload" onClick={handleBulkUpload}>
              BULK UPLOAD
            </button>
          </div>
        </div>
        <div className="table">
          <div className="table-header">
            <span>Group Name</span>
            <span>Assigned Vehicles</span>
            <span>Created Date</span>
            <span>Actions</span>
          </div>
          <div className="table-body">
            {groups.length === 0 ? (
              <p>No rows</p>
            ) : (
              groups.map((group, index) => (
                <div key={index} className="table-row">
                  <span>{group.name}</span>
                  <span>{group.assignedVehicles}</span>
                  <span>{group.createdDate}</span>
                  <span>
                    <button onClick={() => handleEdit(index)}>Edit</button>
                    <button onClick={() => handleDelete(index)}>Delete</button>
                  </span>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="pagination">
          <p>Rows per page: 5</p>
          <div className="pagination-controls">
            <button onClick={() => handlePagination('first', 'Groups')}>&lt;&lt;</button>
            <button onClick={() => handlePagination('prev', 'Groups')}>&lt;</button>
            <button onClick={() => handlePagination('next', 'Groups')}>&gt;</button>
            <button onClick={() => handlePagination('last', 'Groups')}>&gt;&gt;</button>
          </div>
          <p>0–0 of {groups.length}</p>
        </div>
      </div>

      {/* Add/Edit Group Form */}
      {showForm && (
        <div className="form-modal">
          <form className="group-form" onSubmit={handleFormSubmit}>
            <h3>{editIndex !== null ? 'Edit Group' : 'Add Group'}</h3>
            <div className="form-group">
              <label htmlFor="groupName">Group Name:</label>
              <input
                type="text"
                id="groupName"
                value={formData.groupName}
                onChange={(e) =>
                  setFormData({ ...formData, groupName: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="vehicleNumber">Vehicle Number:</label>
              <select
                id="vehicleNumber"
                value={formData.vehicleNumber}
                onChange={(e) =>
                  setFormData({ ...formData, vehicleNumber: e.target.value })
                }
                required
              >
                <option value="">Select Vehicle</option>
                {vehicles.map((vehicle, index) => (
                  <option key={index} value={vehicle.vehicleNumber}>
                    {vehicle.vehicleNumber}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-actions">
              <button type="submit">Save</button>
              <button
                type="button"
                onClick={() => {
                  setShowForm(false);
                  setFormData({ groupName: '', vehicleNumber: '' });
                  setEditIndex(null);
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Vehicle;
