import React, { useState, useEffect } from "react";
import "./Payments.css";

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch payment data from the backend
  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    try {
      const response = await fetch();
      const data = await response.json();
      setPayments(data);
    } catch (error) {
      console.error("Error fetching payment data:", error);
    } finally {
      setLoading(false);
    }
  };

  const downloadInvoicePDF = (paymentId) => {
    const invoiceUrl = ``;

    // Create a temporary link to trigger the download
    const link = document.createElement("a");
    link.href = invoiceUrl;
    link.target = "_blank";
    link.download = `Invoice_${paymentId}.pdf`; // Suggest a filename for the downloaded file
    link.click();
  };

  const viewAllPayments = () => {
    // Navigate to all payments page
    window.location.href = "/all-payments";
  };

  return (
    <div className="payments-container">
      <h1>Payment History</h1>
      {loading ? (
        <p>Loading payment data...</p>
      ) : (
        <table className="payments-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Customer ID</th>
              <th>Vehicle Number</th>
              <th>Payment Date</th>
              <th>Payment ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <tr key={payment.id}>
                <td>{payment.id}</td>
                <td>{payment.customerId}</td>
                <td>{payment.vehicleNumber}</td>
                <td>{new Date(payment.date).toLocaleDateString()}</td>
                <td>{payment.paymentId}</td>
                <td>
                  <button
                    className="action-button"
                    onClick={() => downloadInvoicePDF(payment.paymentId)}
                  >
                    Download PDF
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <button className="view-all-button" onClick={viewAllPayments}>
        View All Payments
      </button>
    </div>
  );
};

export default Payments;
