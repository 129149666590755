import React, { useState } from 'react';
import './Idling.css';

const Idling = () => {
  const [timeDurations, setTimeDurations] = useState([
    { label: '<5 Min', count: 6 },
    { label: '5-15 Min', count: 10 },
    { label: '>15 Min', count: 4 },
  ]);
  const [totalVehicles, setTotalVehicles] = useState(20);
  const [isEditing, setIsEditing] = useState(false);

  const [vehicleData, setVehicleData] = useState([
    {
      vehicleNo: 'V123',
      driverName: 'D1',
      unscheduledDuration: '<20 Min',
      geofenceDuration: '<20 Min',
      idling: '<5 Min',
      dailyKm: '200 KM',
    },
    {
      vehicleNo: 'V124',
      driverName: 'D2',
      unscheduledDuration: '20-60 Min',
      geofenceDuration: '20-60 Min',
      idling: '5-15 Min',
      dailyKm: '150 KM',
    },
    {
      vehicleNo: 'V125',
      driverName: 'D3',
      unscheduledDuration: '>1 Hrs',
      geofenceDuration: '>1 Hrs',
      idling: '>15 Min',
      dailyKm: '180 KM',
    },
  ]);

  const handleDurationChange = (index, value) => {
    const updatedDurations = [...timeDurations];
    const oldLabel = updatedDurations[index].label;
    updatedDurations[index].label = value;
    setTimeDurations(updatedDurations);

    // Update idling in vehicleData dynamically
    setVehicleData((prevData) =>
      prevData.map((vehicle) =>
        vehicle.idling === oldLabel ? { ...vehicle, idling: value } : vehicle
      )
    );

    setTotalVehicles(updatedDurations.reduce((sum, duration) => sum + duration.count, 0));
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="dashboard-container">
      {/* Idling Stoppage Summary Section */}
      <div className="idling-stoppage-summary">
        <div className="table-header">
          <h2>Idling Stoppage</h2>
          <button onClick={handleEditToggle}>
            {isEditing ? 'Save Changes' : 'Customize'}
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th>Time Duration</th>
              <th>Vehicles</th>
            </tr>
          </thead>
          <tbody>
            {timeDurations.map((duration, index) => (
              <tr key={index}>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={duration.label}
                      onChange={(e) => handleDurationChange(index, e.target.value)}
                    />
                  ) : (
                    duration.label
                  )}
                </td>
                <td>{duration.count}</td>
              </tr>
            ))}
            <tr className="grand-total">
              <td>Grand total</td>
              <td>{totalVehicles}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* List View Table */}
      <div className="list-view">
        <table>
          <thead>
            <tr>
              <th>Vehicle No</th>
              <th>Driver Name</th>
              <th>Unscheduled Stoppage</th>
              <th>Time Duration</th>
              <th>Geofence Stoppage</th>
              <th>Time Duration</th>
              <th>Idling</th>
              <th>Time Duration</th>
              <th>Daily Running KM</th>
              <th>KM</th>
            </tr>
          </thead>
          <tbody>
            {vehicleData.map((vehicle, index) => (
              <tr key={index}>
                <td>{vehicle.vehicleNo}</td>
                <td>{vehicle.driverName}</td>
                <td>{vehicle.unscheduledDuration}</td>
                <td>--</td>
                <td>{vehicle.geofenceDuration}</td>
                <td>--</td>
                <td>{vehicle.idling}</td>
                <td>--</td>
                <td>{vehicle.dailyKm}</td>
                <td>--</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Idling;
