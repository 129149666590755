import React, { useState } from "react";

import "./VeyzaSupport.css";

const VeyzaSupport = () => {
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [complaintType, setComplaintType] = useState("");
  const [description, setDescription] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [formError, setFormError] = useState("");

  const complaintTypes = ["Software Issue"];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!vehicleNumber || !contactName || !email || !complaintType) {
      setFormError("All required fields must be filled out.");
      return;
    }

    setFormError("");

    const formData = {
      vehicleNumber,
      contactName,
      email,
      complaintType,
      description,
    };

    // const scriptURL = process.env.REACT_APP_SUPPORT_SHEET_URL;
    console.log(formData); // Log the data before sending

    const response = await fetch(process.env.REACT_APP_SUPPORT_SHEET_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "no-cors", //localhost
      body: JSON.stringify(formData),
    });

    const responseText = await response.text(); // Capture response text
    console.log("Full Response", response);
    if (response.ok) {
      console.log("Response OK:", responseText); // Log successful response
      alert("Message sent successfully!");
    } else {
      console.error("Error details:", responseText); // Log error response details
      alert("Failed to send message. Please try again.");
    }
  };

  const handleAttachmentChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAttachment(file);
    }
  };

  return (
    <div className="support-container">
      <h2>Veyza Support</h2>
      <p>If you're facing any issues, please fill out the form below.</p>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Vehicle Number</label>
          <input
            type="text"
            placeholder="Enter Vehicle Number"
            value={vehicleNumber}
            onChange={(e) => setVehicleNumber(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Contact Name</label>
          <input
            type="text"
            placeholder="Enter Contact Name"
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>
            Email <span className="required">*</span>
          </label>
          <input
            type="email"
            placeholder="Enter Email Address"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>
            Complaint Type <span className="required">*</span>
          </label>
          <select
            required
            value={complaintType}
            onChange={(e) => setComplaintType(e.target.value)}
          >
            <option value="">Select Complaint Type</option>
            {complaintTypes.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>
            Description<span className="required">*</span>
          </label>
          <textarea
            rows="5"
            placeholder="Enter Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>

        {/* Attachment Input */}
        <div className="form-group">
          <label>Attachment (Optional)</label>
          <input
            type="file"
            accept="image/*, .pdf, .doc, .docx"
            onChange={handleAttachmentChange}
          />
          {attachment && <p>File: {attachment.name}</p>}
        </div>

        {formError && <p className="error-message">{formError}</p>}

        <div className="form-actions">
          <button
            type="reset"
            className="reset-btn"
            onClick={() => {
              setVehicleNumber("");
              setContactName("");
              setEmail("");
              setComplaintType("");
              setDescription("");
              setAttachment(null); // Clear attachment
            }}
          >
            Reset
          </button>
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default VeyzaSupport;
