import React, { Fragment, useEffect, useState } from "react";
import "./Geofences.css";
import { OlaMaps } from "../../../libs/OlaMapsWebSDK/olamaps-web-sdk.umd.js";

const Geofences = () => {
  const [geofencesData, setGeofencesData] = useState([]);

  const createCircle = (center, radiusInKm, numPoints = 64) => {
    const earthRadius = 6371; // Earth radius in km
    const [lng, lat] = center;
    const coordinates = [];

    for (let i = 0; i <= numPoints; i++) {
      const angle = (i * 360) / numPoints;
      const radians = (Math.PI / 180) * angle;
      const dx = (radiusInKm / earthRadius) * Math.cos(radians);
      const dy = (radiusInKm / earthRadius) * Math.sin(radians);

      const newLat = lat + (dy * 180) / Math.PI;
      const newLng =
        lng + (dx * 180) / (Math.PI * Math.cos((lat * Math.PI) / 180));
      coordinates.push([newLng, newLat]);
    }

    coordinates.push(coordinates[0]); // Close the polygon
    return {
      type: "Polygon",
      coordinates: [coordinates],
    };
  };

  useEffect(() => {
    const fetchGeofences = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/v0/geofences`
        );
        const geofences = await response.json(); // Wait for the promise to resolve

        // Check if the response is an array before calling map
        if (Array.isArray(geofences)) {
          const formattedGeofences = geofences.map((geofence) => ({
            name: geofence.geoFenceName,
            center: [geofence.longitude, geofence.latitude], // long,lat
            radius: geofence.radius,
          }));
          setGeofencesData(formattedGeofences);
        } else {
          console.error("API response is not an array:", geofences);
        }
      } catch (error) {
        console.error("Failed to fetch geofences:", error);
      }
    };
    fetchGeofences();
  }, []);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_OLA_MAPS_API_KEY;
    if (!apiKey) {
      console.error("Ola Maps API key is missing.");
      return;
    }

    let myMap;

    try {
      const olaMaps = new OlaMaps({ apiKey });

      const container = document.getElementById("geofence-map-container");
      if (!container) {
        console.error(
          'Map container with id "geofence-map-container" not found.'
        );
        return;
      }

      myMap = olaMaps.init({
        container: "geofence-map-container",
        style:
          "https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json",
        center: [78.9629, 20.5937],
        zoom: 5,
      });

      myMap.on("load", () => {
        const geofences = {
          type: "FeatureCollection",
          features: geofencesData.map((geofence) => ({
            type: "Feature",
            geometry: createCircle(geofence.center, geofence.radius),
            properties: { name: geofence.name },
          })),
        };

        myMap.addSource("geofences", {
          type: "geojson",
          data: geofences,
        });

        myMap.addLayer({
          id: "geofence-layer",
          type: "fill",
          source: "geofences",
          paint: {
            "fill-color": "#008000",
            "fill-opacity": 0.3,
          },
        });

        myMap.addLayer({
          id: "geofence-border",
          type: "line",
          source: "geofences",
          paint: {
            "line-color": "#000000",
            "line-width": 2,
          },
        });

        // Create a popup for hover
        const popup = new olaMaps.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        // Show popup on hover
        myMap.on("mousemove", "geofence-layer", (e) => {
          myMap.getCanvas().style.cursor = "pointer";

          const feature = e.features[0];
          const coordinates = feature.geometry.coordinates[0][0];
          const name = feature.properties.name;

          popup
            .setLngLat(coordinates)
            .setHTML(`<strong>${name}</strong>`)
            .addTo(myMap);
        });

        // Remove popup on mouse leave
        myMap.on("mouseleave", "geofence-layer", () => {
          myMap.getCanvas().style.cursor = "";
          popup.remove();
        });

        myMap.on("click", "geofence-layer", (e) => {
          const feature = e.features[0];
          const coordinates = feature.geometry.coordinates[0][0];
          const name = feature.properties.name;

          // Set the popup content and position
          popup
            .setLngLat(coordinates)
            .setHTML(`<strong>${name}</strong>`)
            .addTo(myMap);
        });
        myMap.on("mousemove", "geofence-layer", (e) => {
          myMap.getCanvas().style.cursor = "pointer";

          const feature = e.features[0];
          let coordinates = feature.geometry.coordinates[0][0].slice();
          const name = feature.properties.name;

          // Ensure popup appears in a visible part of the map
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup
            .setLngLat(coordinates)
            .setHTML(`<strong>${name}</strong>`)
            .addTo(myMap);
        });
      });
    } catch (error) {
      console.error("Failed to initialize Ola Maps:", error);
    }

    return () => {
      if (myMap) myMap.remove();
    };
  }, [geofencesData]);

  return (
    <Fragment>
      <div className="geofence-header">
        <button className="back-button">Back to Maps View</button>
        <input
          type="text"
          className="search-input"
          placeholder="Search for Geofences"
        />
        <div className="actions">
          <button className="bulk-upload">Bulk Upload</button>
          <button className="create-geofence">Create new Geofence</button>
        </div>
      </div>

      <div
        id="geofence-map-container"
        style={{ height: "100vh", width: "100%" }}
      ></div>
    </Fragment>
  );
};

export default Geofences;
