import React, { useEffect, useState } from "react";
import "./Trips.css";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";

const Trips = () => {
  const [selectedTimeRange, setSelectedTimeRange] = useState("Last 7 Days");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [newTrip, setNewTrip] = useState({
    vehicle: "",
    routeDetails: "",
    tripStatus: "Scheduled",
    startInfo: "",
    eta: "",
    distance: "",
    fuel: "",
  });
  const [tripData, setTripData] = useState([]);

  // Fetch trip data from API
  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/v0/all-trips-data`
        );
        const data = await response.json();

        // Map API response to tripData structure
        const mappedData = data.map((trip) => ({
          vehicle: trip.vehicleNo,
          routeDetails: trip.routeDetail,
          tripStatus: trip.tripStatus,
          startInfo: trip.startInfo || null,
          eta: trip.eta || null, // API doesn't provide ETA; adjust as needed
          distance: `${trip.distance} km`,
          fuel: `${trip.fuel} L`,
        }));

        setTripData(mappedData);
      } catch (error) {
        console.error("Error fetching trip data:", error);
      }
    };

    fetchTrips();
  }, []);

  const handleTimeRangeChange = (e) => {
    setSelectedTimeRange(e.target.value);
  };

  const handleCreateTripClick = () => {
    setIsFormVisible(true);
  };

  const handleCloseForm = () => {
    setIsFormVisible(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTrip((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const createTrip = async (trip) => {
    try {
      const mappedTrip = {
        vehicleNo: trip.vehicle,
        routeDetail: trip.routeDetails,
        tripStatus: trip.tripStatus,
        startInfo: trip.startInfo,
        eta: trip.eta,
        distance: trip.distance.replace(" km", ""), // Remove 'km' if backend expects a number
        fuel: trip.fuel.replace(" L", ""), // Remove 'L' if backend expects a number
      };

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/v0/create-trip`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(mappedTrip),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create trip");
      }

      const result = await response.json();
      console.log("Trip created successfully:", result);
      return result;
    } catch (error) {
      console.error("Error creating trip:", error);
      return null;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // API call to create a new trip
    const createdTrip = await createTrip(newTrip);

    if (createdTrip) {
      setTripData((prevData) => [...prevData, createdTrip]); // Update the table with the new trip
      setIsFormVisible(false);
      setNewTrip({
        vehicle: "",
        routeDetails: "",
        tripStatus: "Scheduled",
        startInfo: "",
        eta: "",
        distance: "",
        fuel: "",
      });
    }
  };

  const handleDeleteTrip = (index) => {
    setTripData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleEditTrip = (index) => {
    const tripToEdit = tripData[index];
    setNewTrip(tripToEdit);
    setIsFormVisible(true);
    setTripData((prevData) => prevData.filter((_, i) => i !== index));
  };

  // Format datetime to shorter terms
  const formatDatetime = (datetime) => {
    if (!datetime) return "N/A"; // Handle missing or empty date
    const date = new Date(datetime);
    if (isNaN(date.getTime())) return "Invalid Date"; // Handle invalid date
    const options = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  // Download as CSV
  const downloadCSV = () => {
    const csvRows = [
      [
        "Vehicle",
        "Route Details",
        "Trip Status",
        "Start Info",
        "ETA",
        "Distance",
        "Fuel",
      ], // Header row
      ...tripData.map((trip) => [
        trip.vehicle,
        trip.routeDetails,
        trip.tripStatus,
        formatDatetime(trip.startInfo),
        formatDatetime(trip.eta),
        trip.distance,
        trip.fuel,
      ]),
    ];

    const csvContent = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "trip_data.csv";
    link.click();
  };

  // Download as PDF
  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.setFont("helvetica", "normal");
    doc.text("Trip Data Report", 14, 10);

    const headers = [
      "Vehicle",
      "Route Details",
      "Trip Status",
      "Start Info",
      "ETA",
      "Distance",
      "Fuel",
    ];
    const rows = tripData.map((trip) => [
      trip.vehicle,
      trip.routeDetails,
      trip.tripStatus,
      formatDatetime(trip.startInfo),
      formatDatetime(trip.eta),
      trip.distance,
      trip.fuel,
    ]);

    doc.autoTable({
      head: [headers],
      body: rows,
      startY: 20,
    });

    doc.save("trip_data.pdf");
  };

  // Download as Excel
  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "Vehicle",
        "Route Details",
        "Trip Status",
        "Start Info",
        "ETA",
        "Distance",
        "Fuel",
      ], // Header row
      ...tripData.map((trip) => [
        trip.vehicle,
        trip.routeDetails,
        trip.tripStatus,
        formatDatetime(trip.startInfo),
        formatDatetime(trip.eta),
        trip.distance,
        trip.fuel,
      ]),
    ]);
    XLSX.utils.book_append_sheet(wb, ws, "Trip Data");
    XLSX.writeFile(wb, "trip_data.xlsx");
  };

  return (
    <div className="container">
      {/* Header with tabs */}
      <div className="header">
        <div className="tabs">
          <span className="active">All Trips</span>
          <button className="backdated-button">Backdated Trips</button>
        </div>

        <div className="actions">
          <div className="search-bar">
            <i className="fas fa-search"></i>
            <input
              type="text"
              placeholder="Search for Vehicles, Trips & more"
            />
          </div>

          {/* Time Dropdown */}
          <div className="time-dropdown">
            <select
              className="dropdown-select"
              value={selectedTimeRange}
              onChange={handleTimeRangeChange}
            >
              <option value="Last 24 Hours">Last 24 Hours</option>
              <option value="Last 7 Days">Last 7 Days</option>
              <option value="Last 15 Days">Last 15 Days</option>
              <option value="Last 30 Days">Last 30 Days</option>
            </select>
          </div>

          <button className="create-trip" onClick={handleCreateTripClick}>
            Create Trip
          </button>
        </div>
      </div>

      {/* Show Create Trip Form */}
      {isFormVisible && (
        <div className="create-trip-form">
          <div className="form-header">
            <h3>{newTrip.vehicle ? "Edit Trip" : "Create Trip"}</h3>
            <button className="close-form" onClick={handleCloseForm}>
              X
            </button>
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label>Driver Name</label>
              <input
                type="text"
                name="vehicle"
                value={newTrip.vehicle}
                onChange={handleInputChange}
                placeholder="Enter Driver Name"
                required
              />
            </div>

            <div className="form-group">
              <label>Trip Start Date and Time</label>
              <input
                type="datetime-local"
                name="startInfo"
                value={newTrip.startInfo}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Route Details</label>
              <input
                type="text"
                name="routeDetails"
                value={newTrip.routeDetails}
                onChange={handleInputChange}
                placeholder="Enter Route Details"
                required
              />
            </div>

            <div className="form-group">
              <label>Vehicle Number</label>
              <input
                type="text"
                name="fuel"
                value={newTrip.fuel}
                onChange={handleInputChange}
                placeholder="Enter Vehicle Number"
                required
              />
            </div>

            <button type="submit" className="submit-btn">
              {newTrip.vehicle ? "Update Trip" : "Create Trip"}
            </button>
          </form>
        </div>
      )}

      {/* Table */}
      <div className="table-container">
        <div className="table-header">
          <span>Vehicle</span>
          <span>Route Details</span>
          <span>Trip Status</span>
          <span>Start Info</span>
          <span>ETA</span>
          <span>Distance</span>
          <span>Fuel</span>
          <span>Actions</span>
        </div>
        {tripData.length > 0 ? (
          tripData.map((trip, index) => (
            <div className="table-row" key={index}>
              <span>{trip.vehicle}</span>
              <span>{trip.routeDetails}</span>
              <span>{trip.tripStatus}</span>
              <span>{formatDatetime(trip.startInfo)}</span>
              <span>{formatDatetime(trip.eta)}</span>
              <span>{trip.distance}</span>
              <span>{trip.fuel}</span>
              <span>
                <button
                  className="edit-btn"
                  onClick={() => handleEditTrip(index)}
                >
                  Edit
                </button>
                <button
                  className="delete-btn"
                  onClick={() => handleDeleteTrip(index)}
                >
                  Delete
                </button>
              </span>
            </div>
          ))
        ) : (
          <div className="table-row empty">No data available</div>
        )}
      </div>

      {/* Download Reports */}
      <div className="download-reports">
        <span>Download Reports</span>
        <button onClick={downloadCSV}>CSV</button>
        <button onClick={downloadPDF}>PDF</button>
        <button onClick={downloadExcel}>Excel</button>
      </div>
    </div>
  );
};

export default Trips;
