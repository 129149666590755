import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import "./VehicleService.css"; // Import the CSS file

// Register necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

const VehicleService = () => {
  const [vehicleServiceData, setVehicleServiceData] = useState([]);
  const [filteredServiceData, setFilteredServiceData] = useState([]);
  const [chartData /*setChartData*/] = useState({
    labels: ["0-30 days", "30-60 days", "60-90 days", "90+ days"],
    datasets: [
      {
        label: "Services",
        data: [12, 18, 10, 8],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50"],
        hoverOffset: 4,
      },
    ],
  });

  useEffect(() => {
    // Sample data
    const sampleData = [
      {
        vehicleNumber: "KA-01-1234",
        driverName: "John Doe",
        driverContact: "9876543210",
        lastServiceDate: "2024-12-01",
        daysSinceService: 25,
        serviceType: "Oil Change",
        serviceCenter: "ABC Service Center",
        serviceStatus: "Completed",
      },
      {
        vehicleNumber: "KA-02-5678",
        driverName: "Jane Smith",
        driverContact: "8765432109",
        lastServiceDate: "2024-11-15",
        daysSinceService: 45,
        serviceType: "Tire Rotation",
        serviceCenter: "XYZ Service Center",
        serviceStatus: "Pending",
      },
      {
        vehicleNumber: "KA-03-9101",
        driverName: "Mark Johnson",
        driverContact: "7654321098",
        lastServiceDate: "2024-10-10",
        daysSinceService: 75,
        serviceType: "Engine Check",
        serviceCenter: "LMN Service Center",
        serviceStatus: "Completed",
      },
      {
        vehicleNumber: "KA-04-1121",
        driverName: "Emily Davis",
        driverContact: "6543210987",
        lastServiceDate: "2024-09-01",
        daysSinceService: 115,
        serviceType: "Battery Replacement",
        serviceCenter: "PQR Service Center",
        serviceStatus: "In Progress",
      },
    ];

    setVehicleServiceData(sampleData);
    setFilteredServiceData(sampleData);
  }, []);

  const handlePieClick = (event, elements) => {
    if (!elements.length) return;

    const clickedIndex = elements[0].index;
    const ranges = [
      { min: 0, max: 30 },
      { min: 30, max: 60 },
      { min: 60, max: 90 },
      { min: 90, max: Infinity },
    ];
    const range = ranges[clickedIndex];

    const filtered = vehicleServiceData.filter(
      (vehicle) =>
        vehicle.daysSinceService >= range.min &&
        vehicle.daysSinceService < range.max
    );
    setFilteredServiceData(filtered);
  };

  return (
    <div className="vehicle-service-container">
      <h2 className="title">Service - Days Since Last Service</h2>
      <div className="chart-container">
        <Pie
          data={chartData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (tooltipItem) =>
                    `${tooltipItem.label}: ${tooltipItem.raw} vehicles`,
                },
              },
            },
          }}
          height={200}
          width={200}
          onClick={(event, elements) => handlePieClick(event, elements)}
        />
      </div>
      <h3 className="subtitle">List View</h3>
      <div className="table-container">
        <table className="data-table">
          <thead>
            <tr>
              <th>Vehicle No.</th>
              <th>Driver Name</th>
              <th>Driver Contact</th>
              <th>Last Service Date</th>
              <th>Days Since Service</th>
              <th>Service Type</th>
              <th>Service Center</th>
              <th>Service Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredServiceData.map((vehicle, index) => (
              <tr key={index}>
                <td>{vehicle.vehicleNumber}</td>
                <td>{vehicle.driverName}</td>
                <td>{vehicle.driverContact}</td>
                <td>{vehicle.lastServiceDate}</td>
                <td>{vehicle.daysSinceService}</td>
                <td>{vehicle.serviceType}</td>
                <td>{vehicle.serviceCenter}</td>
                <td>{vehicle.serviceStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VehicleService;
